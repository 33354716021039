import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";
import { AppListResponse } from "../shared/app.response";
import { PetModel } from "./models/pet-model";
import {AuthenticationService} from "../authentication/authentication.service";
import { PetPhysicalActivityResponse } from "./models/pet-physical-activity-model";
import {SavePetResponse} from "./models/save-pet-response";
import { LoadingService } from "../shared/loading.service";

@Injectable({
    providedIn: 'root'
})
export class PetService {
  apiUrl = `${environment.baseApimUrl}/pet`;
  constructor(private http: HttpClient, private authService: AuthenticationService, private loadingService: LoadingService) { }

  list(itemsPerPage: number, pageNumber: number, search: string = ''): Observable<AppListResponse<PetModel>> {
      const url = `${this.apiUrl}/${this.authService.getUserId()}/pets`;
      const searchObject = {
          SearchExpression: '',
          Filters: [
              {
                  Key: 'nome',
                  Operator: 'StartsWith',
                  Value: search
              }
          ],
          SortField: 'nome',
          SortOrder: 2,
          PageNumber: pageNumber,
          PageSize: itemsPerPage
      };

      return this.http.post<AppListResponse<PetModel>>(url, searchObject);
  }

  filterPetsByNameAndTutor(name: string, tutorId: number, pageNumber = 0, itemsPerPage = 10): Observable<AppListResponse<PetModel>> {
    const petFilter = {
      SearchExpression: '',
      Filters: [
        {
            Key: 'nome',
            Operator: 'StartsWithFullText',
            Value: name
        },
        {
          Key: 'tutorId',
          Operator: 'Equals',
          Value: tutorId
        }
      ],
      SortField: 'nome',
      SortOrder: 2,
      PageNumber: pageNumber,
      PageSize: itemsPerPage
    };
    return this.http.post<AppListResponse<PetModel>>(
      `${this.apiUrl}/list`,
      petFilter
    );
  }

  getById(id: number): Observable<PetModel> {
      const url = `${this.apiUrl}/${id}`;
      return this.http.get<PetModel>(url);
  }

  savePet(pet: PetModel): Observable<SavePetResponse> {
      const headers = this.loadingService.getLoadingHeader();
      const url = this.apiUrl + '/create';
      return this.http.post<SavePetResponse>(url, pet, {headers});
  }

  updatePet(idPet: number, pet: PetModel): Observable<SavePetResponse> {
      const headers = this.loadingService.getLoadingHeader();
      const url = `${this.apiUrl}/${idPet}/update`;
      return this.http.put<SavePetResponse>(url, pet, {headers});
  }

  getPhysicalActivities(clinicalConditions: number[], breed: number, specie: string, productId: number = null): Observable<PetPhysicalActivityResponse> {
    // TODO: ALTERAR PARA CLINICAL CHARACTERISTICS APÓS REFACTOR NO ENDPOINT
    const url = `${this.apiUrl}/physical-activities`;
    return this.http.post<PetPhysicalActivityResponse>(url, { clinicalConditions, breed, specie, productId });
  }
}
