import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PwaService } from './services/pwa/pwa.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from './services/notification/notification.service';
import { CrossDomainSharingService } from './services/cross-domain-sharing.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'af-notification';
  urlLandingpage: SafeResourceUrl;

  constructor(private pwaService: PwaService,
    private notificationService: NotificationService,
    private crossDomainService: CrossDomainSharingService) { }

  ngOnInit(): void {
    this.pwaService.verifyUpdate();
    this.sendMessageCrossDomain();
  }

  ngAfterViewInit(): void {
    setInterval(() => {
      this.pwaService.verifyUpdate();
    }, 900000);
  }

  sendMessageCrossDomain() {
    this.crossDomainService.postCrossDomainMessage();
  }
}
